import gql from 'graphql-tag';
const getKeywordsSearch = gql `
  query GetPolicyViolationsKeywordSearchItems($filter_by_search_ui: String!) {
    get_policy_violations_keyword_search_items(filter_by_search_ui: $filter_by_search_ui) {
      policy_violations_named_filters {
        value
        name
        icon_url
        group
      }
    }
  }
`;
const exportToJira = gql `
  mutation PolicyViolationExportToJira($id: ID!, $body: PolicyViolationEportToJiraParams!) {
    policy_violation_export_jira(id: $id, body: $body) {
      external_key
      external_url
      id
      integration_icon_url
      integration_type
    }
  }
`;
const v3_list = gql `
  query PolicyViolationListV3(
    $cursor: String
    $order_by: String
    $include: String
    $was_discovered_after: Date
    $filter_by_importance_tag: [FilterByImportanceTagEnum]
    $filter_by_product: [FilterByProductEnum]
    $filter_by_violation_status: [PolicyViolationStatusEnum]
    $filter_by_relevance: [String]
    $filter_by_asset_group_id: [ID]
    $filter_by_asset_ownership: String
    $filter_by_related_first_party_asset_ids: [ID]
    $filter_by_no_asset_group: Boolean
    $filter_by_asset_vendor_ids: [String]
    $filter_by_policy_rule_id: [ID]
    $filter_by_policy_rule_type_id: [ID]
    $filter_by_ongoing_triaging_effort: Boolean
    $is_eligible_for_auto_remediation: Boolean
    $filter_by_named_filter: String
    $filter_by_text: String
    $filter_by_kspm_named_filter: String
    $filter_by_has_policy_violations_created_on_month: String
    $filter_by_sast_named_filter: String
    $filter_by_asset_discovered_via: [String]
    $filter_by_asset_name: String
  ) {
    policy_violations_list_v2(
      cursor: $cursor
      order_by: $order_by
      include: $include
      was_discovered_after: $was_discovered_after
      filter_by_importance_tag: $filter_by_importance_tag
      filter_by_product: $filter_by_product
      filter_by_violation_status: $filter_by_violation_status
      filter_by_relevance: $filter_by_relevance
      filter_by_asset_group_id: $filter_by_asset_group_id
      filter_by_asset_ownership: $filter_by_asset_ownership
      filter_by_related_first_party_asset_ids: $filter_by_related_first_party_asset_ids
      filter_by_no_asset_group: $filter_by_no_asset_group
      filter_by_asset_vendor_ids: $filter_by_asset_vendor_ids
      filter_by_policy_rule_id: $filter_by_policy_rule_id
      filter_by_policy_rule_type_id: $filter_by_policy_rule_type_id
      filter_by_ongoing_triaging_effort: $filter_by_ongoing_triaging_effort
      is_eligible_for_auto_remediation: $is_eligible_for_auto_remediation
      filter_by_named_filter: $filter_by_named_filter
      filter_by_text: $filter_by_text
      filter_by_kspm_named_filter: $filter_by_kspm_named_filter
      filter_by_has_policy_violations_created_on_month: $filter_by_has_policy_violations_created_on_month
      filter_by_sast_named_filter: $filter_by_sast_named_filter
      filter_by_asset_discovered_via: $filter_by_asset_discovered_via
      filter_by_asset_name: $filter_by_asset_name
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_violations {
        id
        status
        date_created
        violated_policy_rule {
          id
          severity
          relevance
          policy_rule_type {
            title
          }
        }
        affected_asset {
          id
          name
          url
          asset_type_icon_url
        }
        included_http_trace {
          http_request
        }
        included_sast_issue_summary {
          code_owner_name
          code_owner_email
          detected_in_branch_name
          detected_in_branch_url
          detected_in_file_name
          detected_in_file_path
          detected_on_line
          issue_code_snippet
          see_issue_in_platform_url
        }
      }
    }
  }
`;
const v2_get = gql `
  query PolicyViolationGetVersion2($id: ID, $include: String) {
    policy_violation_details_v2(id: $id, include: $include) {
      id
      date_created
      date_resolved
      date_last_checked
      exception_type
      additional_info
      status
      exception_date_created
      exception_explanation
      custom_description
      custom_recommendation
      custom_secure_code
      custom_performed_attack
      custom_expected_response
      custom_real_response
      affected_asset_details
      external_integrations {
        id
        external_key
        external_url
        integration_icon_url
        integration_type
      }
      auto_remediation_info {
        remediation_instructions
        remediation_url
      }
      http_trace {
        http_request
        http_response
      }
      affected_asset {
        id
        name
        url
        cloud_console_url
        asset_type
        asset_type_icon_url
        asset_type_name
        date_created
        hosted_on
        aws_arn
        belongs_to_asset_group {
          id
          name
        }
        belongs_to_cloud_account {
          id
          cloud_account_icon_url
          cloud_account_type_name
          discovered_via_cloud_authenticator_id
          customer_supplied_name
        }
      }
      violated_policy_rule {
        id
        policy_rule_type {
          id
          title
          description
          recommendation
          secure_code
          default_relevance
          screenshot_url
          compliance_policy_references {
            compliance_standard
            compliance_standard_criteria
            description
            link
          }
          related_rule_types {
            id
            title
            description
            recommendation
            default_relevance
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
          }
        }
        relevance
        severity
      }
      included_sast_issue_summary {
        code_owner_name
        code_owner_email
        detected_in_branch_name
        detected_in_branch_url
        detected_in_file_name
        detected_in_file_path
        detected_on_line
        issue_code_snippet
        see_issue_in_platform_url
      }
    }
  }
`;
export default {
    exportToJira,
    getKeywordsSearch,
    v2_get,
    v3_list,
};
