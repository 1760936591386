import React, { memo } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyle = makeStyles({
  chevronIcon: {
    '&.open': {
      transform: 'rotate(180deg)',
    },
    transition: 'transform 160ms cubic-bezier(1, 0, 0, 1)',
  },

  menuIconBox: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: '16px',
    maxHeight: 40,
    maxWidth: 40,
    minHeight: 40,
    minWidth: 40,
    width: 40,
  },

  menuOuter: {
    '&.active': {
      backgroundColor: palette.brand35,
    },
    '&.bottom': {
      marginTop: 'auto',
    },
    '&:hover': {
      backgroundColor: palette.brand20,
    },
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,

    paddingRight: 0,

    paddingTop: 0,
  },

  menuText: {
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

function ConfigurableLSHMenuChevron({ navDrawerOpen, setNavDrawerOpen }) {
  const css = useStyle();

  return (
    <div
      className={css.menuOuter + ' bottom'}
      data-testid={'__navDrawerButton'}
      onClick={() => setNavDrawerOpen(s => !s)}
      type="button"
    >
      <div className={css.menuIconBox}>
        <ChevronRightIcon
          classes={{
            root: `${css.chevronIcon} ${navDrawerOpen ? 'open' : ''}`,
          }}
          color="inherit"
        />
      </div>
      <div className={css.menuText}></div>
    </div>
  );
}

export default memo(ConfigurableLSHMenuChevron);
