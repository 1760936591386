import GavelIcon from '@mui/icons-material/Gavel';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ProtectIcon from '@mui/icons-material/Security';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import WarningIcon from '@mui/icons-material/Warning';
import DTIcon from '@dt/brand/dt-logo-icon-only';
import APISecureIcon from '@dt/brand/product-icon-api-secure';
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
import BrandIconSupplierTrust from '@dt/brand/product-icon-supplier-trust';
import BrandIconSupplyChainSecurity from '@dt/brand/product-icon-supply-chain-security';
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
import { capitalize } from '@dt/functions';
import { ToolkitIcon } from '@dt/material-components';

const redirectExceptions = ['/api/shadow-assets'];

/*
    When in /mobile-secure
    + For entries
      - If we're in mobile-secure and the destination doesn't contain '/mobile-secure'/ AND doesn't already have redirect: true
        - redirect: true
        - target: _self
        - to: to: needs to be rewritten to /mobile/${to}

    + For  subentries:
      - If a link has redirect: true it means that we want to open in a new tab
        - redirect: true
        - target: "_blank"
        - to: existing to

      - If a link has redirect: false (or no redirect field) then all links except the ones with /mobile-secure need to have:
       - redirect: true
       - target: "_self"
       - to: needs to be rewritten to /mobile/${to}

    When in /mobile
      - Nothing to do, the object already has the proper urls
    */
const getEntriesWithRedirect = ({ isMobileSecure, entries }) =>
  entries.map(entry => {
    let target = entry.target || '_self';
    let to = entry.to;
    let redirect = entry.redirect || false;
    if (isMobileSecure && entry.to) {
      const hasRedirect = 'redirect' in entry;
      const isMobileSecureUrl = entry.to.match(/\/mobile-secure\//g);
      const needsUpdate = !isMobileSecureUrl && !hasRedirect;
      if (needsUpdate) {
        to = `/mobile${entry.to}`;
        redirect = true;
        target = '_self';
      }
    }

    const updatedSubEntries = entry.subEntries.map(sub => {
      if (isMobileSecure) {
        const genericLink = sub.genericLink || false;
        let target = sub.target || '_self';
        const to = sub.to;
        const redirect = sub.redirect || false;

        if (redirect && !genericLink) {
          target = '_blank';
          return {
            ...sub,
            redirect,
            target,
            to,
          };
        }

        const isMobileSecureUrl = sub.to.match(/\/mobile-secure\//g);
        const updatedTo = `${!isMobileSecureUrl && !genericLink ? '/mobile' : ''}${sub.to}`;

        return {
          ...sub,
          redirect: isMobileSecure && isMobileSecureUrl ? false : true,
          target: '_self',
          to: !redirectExceptions.includes(sub.to) ? updatedTo : sub.to,
        };
      }
      return sub;
    });

    return {
      ...entry,
      redirect,
      subEntries: updatedSubEntries,
      target,
      to,
    };
  });

export const MenuDashboard = {
  entries: [],
  product: {
    icon: DTIcon,
    name: 'Data Theorem',
    to: '/dashboard',
  },
};

export const MenuMobile = ({ isMobileSecure = false }) => {
  const product = {
    icon: MobileSecureIcon,
    name: 'Mobile Secure',
    to: '/issues/priority',
    ...(isMobileSecure && {
      redirect: true,
      target: '_self',
      to: `/mobile/issues/priority`,
    }),
  };

  const entries = [
    {
      icon: WarningIcon,
      name: 'Issues',
      subEntries: [],
      to: '/issues/search',
    },

    {
      exact: false,
      icon: GavelIcon,
      name: 'Policies',
      redirect: !isMobileSecure,
      subEntries: [],
      target: '_self',
      to: '/policies',
    },

    {
      // No link
exact: false,
      
icon: GroupWorkIcon,
      
name: 'ASM', 
      subEntries: [
        {
          name: 'Inventory',
          redirect: !isMobileSecure,
          target: '_self',
          to: '/mobile-secure/v2/inventory/apps',
        },
        {
          name: 'Shadow Assets',
          to: '/api/shadow-assets',
        },
        {
          name: 'ASM Setup',
          redirect: true,
          to: '/cloud/asm-setup',
        },
      ],
      to: null,
    },

    {
      exact: true,
      icon: ToolkitIcon,
      
name: 'Hacker Toolkits', 
      // No link
subEntries: [
        {
          name: 'Remote P1 Exploits',
          to: '/issues/security',
        },
        {
          name: 'Gatekeeper Gambling',
          to: '/gatekeeper-gambling/blockers',
        },
        {
          name: 'Keys to the Kingdom',
          to: '/kttk/secrets',
        },
        {
          name: 'Mobile Phishing',
          redirect: !isMobileSecure,
          target: '_self',
          to: '/mobile-secure/mobile-phishing',
        },
        {
          name: 'App Store Answers',
          to: '/ios/privacy',
        },
        {
          name: 'Play Store Answers',
          to: '/android/privacy',
        },
        {
          name: 'Coffee Shop Compromise',
          to: '/csc/scans',
        },
        {
          exact: false,
          name: 'Hack Me Amadeus',
          redirect: !isMobileSecure,
          target: '_self',
          to: '/mobile-secure/custom-checks',
        },
      ],
      to: null,
    },

    {
      exact: true,
      icon: ProtectIcon,
      name: 'Active Protection',
      subEntries: [
        {
          name: 'Mobile Protect',
          to: '/protect',
        },
        {
          name: 'SCA',
          to: '/sdk-issues/all',
        },
      ],
      to: null,
    },
  ];

  const entriesWithRedirect = getEntriesWithRedirect({
    entries,
    isMobileSecure,
  });

  return {
    entries: entriesWithRedirect,
    product,
    useMobileRouting: !isMobileSecure,
  };
};

export const MenuWeb = {
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      subEntries: [],
      to: '/web/policy-violations',
    },

    {
      exact: false,
      icon: GavelIcon,
      name: 'Policies',
      subEntries: [],
      to: '/policies',
    },

    {
      // No link
exact: false,
      
icon: GroupWorkIcon,
      
name: 'ASM', 
      subEntries: [
        {
          name: 'Inventory',
          to: '/web/inventory',
        },
        {
          
          name: 'Shadow Assets',
          // Not on /web path
to: '/api/shadow-assets',
        },
        {
          name: 'ASM Setup',
          to: '/web/asm-setup',
        },
      ],
      to: null,
    },

    {
      exact: true,
      icon: ToolkitIcon,
      name: 'Hacker Toolkits',
      subEntries: [
        {
          name: 'SQL Injection',
          to: '/web/security-toolkits/sql-injection',
        },
        {
          name: 'Toxic Tokens',
          to: '/web/toxic-tokens',
        },
        {
          name: 'XSS Attack',
          to: '/web/security-toolkits/xss-attack/overview',
        },
        {
          name: 'Hack Me Amadeus',
          to: '/web/custom-checks',
        },
      ],
      to: '/web/security-toolkits',
    },

    {
      exact: true,
      icon: ProtectIcon,
      name: 'Active Protection',
      subEntries: [
        {
          name: 'XSS Protect',
          to: '/web/security-toolkits/xss-protection',
        },
        {
          name: 'Web Protect',
          to: '/web/security-toolkits/web-protect',
        },
      ],
      to: null,
    },
  ],
  product: {
    icon: WebSecureIcon,
    name: 'Web Secure',
    to: '/web',
  },
};

export const MenuManagement = {
  entries: [],
  product: {
    icon: DTIcon,
    name: 'Your Products',
    to: '/dashboard/v2/products',
  },
};

export const MenuDevSecOps = {
  entries: [],
  product: {
    icon: DTIcon,
    name: 'DevSecOps',
    to: '/devsecops',
  },
};

export const MenuApi = {
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      subEntries: [],
      to: '/api/policy-violations',
    },

    {
      exact: false,
      icon: GavelIcon,
      name: 'Policies',
      subEntries: [],
      to: '/policies',
    },

    {
      // No link
exact: false,
      
icon: GroupWorkIcon,
      
name: 'ASM', 
      subEntries: [
        {
          name: 'Inventory',
          to: '/api/discover/inventory',
        },
        {
          name: 'Shadow Assets',
          to: '/api/shadow-assets',
        },
        {
          name: 'ASM Setup',
          to: '/api/asm-setup',
        },
      ],
      to: null,
    },

    {
      exact: true,
      icon: ToolkitIcon,
      
name: 'Hacker Toolkits', 
      // No link
subEntries: [
        {
          name: 'Hack and Extract',
          to: '/api/inspect/hack-and-extract',
        },
        {
          name: 'Detect and Inject',
          to: '/api/inspect/detect-and-inject',
        },
        {
          name: 'SSRF Sploits',
          to: '/api/ssrf-sploits-toolkit',
        },
        {
          name: 'Leaky APIs',
          to: '/api/inspect/leaky-apis',
        },
        {
          name: 'Hack Me Amadeus',
          to: '/api/custom-checks',
        },
      ],
      to: null,
    },

    {
      exact: true,
      icon: ProtectIcon,
      name: 'Active Protection',
      subEntries: [
        {
          name: 'API Protect',
          to: '/api/protect',
        },
      ],
      to: null,
    },
  ],
  product: {
    icon: APISecureIcon,
    name: 'API Secure',
    to: '/api/overview',
  },
};

export const MenuCloud = {
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      subEntries: [],
      to: '/cloud/policy-violations',
    },

    {
      exact: false,
      icon: GavelIcon,
      name: 'Policies',
      subEntries: [],
      to: '/policies',
    },

    {
      // No link
exact: false,
      
icon: GroupWorkIcon,
      
name: 'ASM', 
      subEntries: [
        {
          name: 'Inventory',
          to: '/cloud/inventory',
        },
        {
          
          name: 'Shadow Assets',
          // Not on /cloud path
to: '/api/shadow-assets',
        },
        {
          name: 'ASM Setup',
          to: '/cloud/asm-setup',
        },
      ],
      to: null,
    },

    {
      exact: true,
      icon: ToolkitIcon,
      name: 'Hacker Toolkits',
      subEntries: [
        {
          name: 'Headliner Hacks',
          to: '/cloud/security-toolkits/headliner-hacks',
        },
        {
          name: 'Cloud Leaks',
          to: '/cloud/security-toolkits/cloud-leaks',
        },
        {
          name: 'Code Red',
          to: '/cloud/security-toolkits/code-red',
        },
        {
          name: 'Ransomware',
          to: '/cloud/security-toolkits/ransomware',
        },
        {
          name: 'Cloud Gotchas',
          to: '/cloud/security-toolkits/cloud-gotchas',
        },
        {
          name: 'Cloud XDR',
          to: '/cloud/security-toolkits/xdr',
        },
        {
          name: 'Cloud DLP',
          to: '/cloud/security-toolkits/dlp',
        },
        {
          name: 'Major Malfunction',
          to: '/cloud/security-toolkits/major-malfunction',
        },
      ],
      to: '/cloud/security-toolkits',
    },

    {
      exact: true,
      icon: ProtectIcon,
      name: 'Active Protection',
      subEntries: [
        {
          name: 'CSPM',
          to: '/cloud/policy-violations?filter_by_belongs_to_products=CLOUD_SECURE',
        },
        {
          name: 'CIEM',
          to: '/cloud/active-protection/ciem',
        },
        {
          name: 'Correct and Protect',
          to: '/cloud/security-toolkits/correct-and-protect',
        },
        {
          name: 'IaC Scan',
          to: '/cloud/iac-scan',
        },
      ],
      to: null,
    },
  ],
  product: {
    icon: CloudSecureIcon,
    name: 'Cloud Secure',
    to: '/cloud',
  },
};

export const MenuScs = ({ accountInfo, currentUser }) => {
  let supplierTrustLink = `/supply-chain/supplier-trust`;

  if (!currentUser?.is_accessing_a_vendor && accountInfo?.vendor_id === accountInfo?.current_vendor?.id) {
    const id = accountInfo?.current_vendor?.id || '';
    supplierTrustLink = `/supply-chain/supplier-trust/suppliers/${id}/overview`;
  }

  return {
    entries: accountInfo?.hide_non_vendor_supply_chain_security_sections
      ? [
          {
            icon: GavelIcon,
            name: 'Policies',
            subEntries: [],
            to: '/policies',
          },
          {
            icon: ToolkitIcon,
            name: 'Headliner Hacks',
            subEntries: [],
            to: '/supply-chain/policies/headline-hacks',
          },
          {
            icon: BrandIconSupplierTrust,
            name: capitalize(accountInfo?.vendor_supply_chain_security_whitelabeling_name) || 'Supplier Trust',
            subEntries: [
              {
                name: `${
                  capitalize(accountInfo?.vendor_supply_chain_security_vendor_singular_term) || 'Supplier'
                } Inventory`,
                to: supplierTrustLink,
              },
            ],
            to: null,
          },
        ]
      : [
          {
            icon: GavelIcon,
            name: 'Policies',
            subEntries: [],
            to: '/policies',
          },
          {
            icon: GroupWorkIcon,
            name: 'ASM Setup',
            subEntries: [],
            to: '/supply-chain/asm-setup',
          },
          {
            icon: ToolkitIcon,
            name: 'Headliner Hacks',
            subEntries: [],
            to: '/supply-chain/policies/headline-hacks',
          },
          {
            exact: true,
            icon: StoreMallDirectoryIcon,
            name: 'Supply Chain Software',
            subEntries: [
              {
                name: 'Supply Chain Orgs',
                to: '/supply-chain/vendors',
              },
              {
                name: 'My Apps',
                to: '/supply-chain/my-apps',
              },
              {
                name: 'SBOM Security Issues',
                to: '/supply-chain/security-issues',
              },
              {
                name: 'SBOM',
                to: '/supply-chain/sbom',
              },
            ],
            to: null,
          },
          {
            icon: BrandIconSupplierTrust,
            name: capitalize(accountInfo?.vendor_supply_chain_security_whitelabeling_name) || 'Supplier Trust',
            subEntries: [
              {
                name: `${
                  capitalize(accountInfo?.vendor_supply_chain_security_vendor_singular_term) || 'Supplier'
                } Inventory`,
                to: supplierTrustLink,
              },
            ],
            to: null,
          },
        ],
    product: {
      icon: BrandIconSupplyChainSecurity,
      name: accountInfo?.supply_chain_security_product_top_level_whitelabel_name || 'Supply Chain Secure',
      to: '/supply-chain',
    },
  };
};

export const MenuSupplyChainSecurity = ({ accountInfo }) => {
  return {
    entries: [],
    product: {
      icon: BrandIconSupplyChainSecurity,
      name: accountInfo?.supply_chain_security_product_top_level_whitelabel_name || 'Supply Chain Security',
      to: '/openscan/v2',
    },
  };
};
