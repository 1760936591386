import React, { useCallback, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@apollo/client';
import { Message } from '@components';
import policy_rules from '@dt/graphql-support/horizon/policy_rules';
import { ExtLink, Markdown, RelevanceTag } from '@dt/material-components';
import ComplianceTagStrip from '@dt/material-components/compliance-tag/ComplianceTagStrip';
import { palette } from '@dt/theme';
import gql from 'graphql-tag';
import { LEAKY_APIS_POLICY_RULE_TYPE_ID } from '../../redux/policy_rule_types/util';
import PolicyRuleImportanceTag from './PolicyRuleImportanceTag';
import { PolicyRuleTypesCardEditRelevanceButton } from './PolicyRuleTypesCardEditRelevanceButton';
import PolicyRuleTypesCardRelativeComponents from './PolicyRuleTypesCardRelativeComponents';
import { useTogglePolicyRule } from './use_toggle_policy_rule';

// Some policy rules can only be configured through another page.
// prettier-ignore
const POLICY_RULE_TYPE_EXTERNAL_CONFIGURATION_LOOKUP = {
  // API Publicly Accessible
'007ceb60-b98c-5a19-97f7-31f3f6526816': '/api/inspect/hack-and-extract', 
  
'92eb7e9e-9daa-5922-8167-6a219e0f7287': '/api/inspect/detect-and-inject',        
  // SQL Injection
[LEAKY_APIS_POLICY_RULE_TYPE_ID]: '/api/inspect/leaky-apis'   // API PII Exposed
};

const useStyles = makeStyles({
  root: {
    '&:first-child': {
      borderTopLeftRadius: props => (props.isV2 ? 8 : 4),
      borderTopRightRadius: props => (props.isV2 ? 8 : 4),
    },
    boxShadow: props =>
      props.isV2
        ? 'none'
        : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
});

/*
 * Do not use this directly.
 * Implemntation component of `PolicyRuleTypesCard`.
 */
const PolicyRuleTypesCardImplementationComponent = function PolicyRuleTypesCardImplementation({
  classes,
  policy_id,
  policy_rule_type,
  isV2 = false,
  currentTab,
}) {
  const css = useStyles({ isV2 });
  const [updatePolicyRule, { error: policyRulesUpdateError, loading: policyRulesUpdateLoading }] = useMutation(
    policy_rules.update,
    {
      update: (cache, result) => {
        // Skip if there are errors.
        if (!result || result.errors) {
          return;
        }

        const new_policy_rule = result.data.policy_rules_update.policy_rules[0];
        // Should never happen since the backend always provides this.
        if (!new_policy_rule) {
          return;
        }

        // Should never happen since we're modifying an existing policy rule here.
        if (!policy_rule_type.policy_rule) {
          return;
        }

        // Update corresponding policy rule relevance.
        cache.modify({
          fields: {
            policies_policy_rule_types_list(existing_policy_rule_types_list, { readField }) {
              for (const existing_policy_rule_type of existing_policy_rule_types_list.policy_rule_types) {
                const existing_policy_rule_type_id = readField('id', existing_policy_rule_type);
                if (policy_rule_type.id === existing_policy_rule_type_id) {
                  cache.modify({
                    fields: {
                      policy_rule(existing_policy_rule_type_policy_rule) {
                        // Create updated cache "reference".
                        return cache.writeFragment({
                          data: { relevance: new_policy_rule.relevance },
                          fragment: gql`
                            fragment PolicyRuleTypeV2PolicyRuleUpdate on PolicyRuleTypeV2PolicyRule {
                              relevance
                            }
                          `,
                          id: cache.identify(existing_policy_rule_type_policy_rule),
                        });
                      },
                    },
                    id: cache.identify(existing_policy_rule_type),
                  });
                }
              }

              return existing_policy_rule_types_list;
            },
          },
        });

        // Cleanup cache.
        cache.gc();
      },
    },
  );

  const [
    togglePolicyRule,
    { policyRulesCreateError, policyRulesRemoveError, policyRulesCreateLoading, policyRulesRemoveLoading },
  ] = useTogglePolicyRule();

  // Control the accordian manually.
  // NOTE: This is a *huge* performance improvement. Please do not switch to uncontrolled.
  //       It does make the animation a bit funny when closing the accordian but this
  //       can be fixed by measuring and maintaining the height of the accordian content.
  const [isExpanded, setIsExpanded] = useState(false);
  // Keep track of accordian content & details to manually control the height for expansion.
  // This helps keep the accordian animation smoother when collapsing.
  // The need for this stems from the performance improvement of using `isExpanded` manually.
  const [accordianDetailsHeight, setAccordianDetailsHeight] = useState(null);
  const [accordianActionsHeight, setAccordianActionsHeight] = useState(null);

  const error = policyRulesCreateError || policyRulesRemoveError || policyRulesUpdateError;

  const handleTogglePolicyRuleButton = useCallback(
    event => {
      event.preventDefault();

      // Toggle policy rule on/off for policy rule type.
      togglePolicyRule(policy_id, policy_rule_type);
    },
    [policy_id, policy_rule_type, togglePolicyRule],
  );

  // Event handlers.
  const handleEditRelevanceItemClicked = useCallback(
    relevance => {
      // Should never happen but protect against undefined errors anyways.
      if (!policy_rule_type.policy_rule) return;

      // Update the policy rule's relevance to the relevance selected.
      updatePolicyRule({
        variables: {
          body: { relevance },
          id: policy_id,
          policy_rule_id: policy_rule_type.policy_rule.id,
        },
      });
    },
    [policy_id, policy_rule_type, updatePolicyRule],
  );

  return (
    <Accordion aria-label={`${policy_rule_type.title} Card`} className={css.root} expanded={isExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setIsExpanded(isExpanded => !isExpanded)}>
        <Grid container>
          {/* Left Title */}
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography className={classes.title} variant="subtitle1">
                  {policy_rule_type.title}
                </Typography>
              </Grid>
              <Grid item>
                <PolicyRuleImportanceTag items={policy_rule_type.importance_tags} />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Stats */}
          <Grid item xs={4}>
            <Box alignItems="center" display="flex" justifyContent="flex-end" style={{ gap: '6px', height: '100%' }}>
              {policy_rule_type.included_in_sast_rule_packs && policy_rule_type.group !== 'MOBILE' && (
                <>
                  {currentTab === 'sast' ? (
                    <>
                      {policy_rule_type.included_in_sast_rule_packs.map((rulepack, index) => {
                        let backgroundColor = palette.gray45;
                        switch (rulepack.color) {
                          case 'GREY': {
                            backgroundColor = palette.gray45;
                            break;
                          }
                          case 'ORANGE': {
                            backgroundColor = palette.orange50;
                            break;
                          }
                        }
                        return (
                          <Chip
                            aria-label={rulepack.title}
                            key={index}
                            label={rulepack.title}
                            size="small"
                            style={{ backgroundColor, borderRadius: '4px' }}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {policy_rule_type.policy_rule && (
                        <Chip
                          aria-label={`${policy_rule_type.policy_rule.open_policy_violations_count} Open Violations`}
                          label={`${policy_rule_type.policy_rule.open_policy_violations_count} Open Violations`}
                          size="small"
                        />
                      )}
                    </>
                  )}
                </>
              )}

              <span className={classes.relevance}>
                {policy_rule_type.policy_rule ? (
                  <RelevanceTag relevance={policy_rule_type.policy_rule.relevance} />
                ) : (
                  <Typography variant="caption">Rule Deactivated</Typography>
                )}
              </span>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        {isExpanded ? (
          <Grid
            container
            ref={ref => {
              if (!ref) return;

              setAccordianDetailsHeight(ref.getBoundingClientRect().height);
            }}
          >
            {/* Description */}
            <Grid item xs={12}>
              <Typography component="div" gutterBottom variant="body2">
                <Markdown inline text={policy_rule_type.description} />
              </Typography>
            </Grid>

            {/* Compliance Standard References */}
            <Grid item xs={12}>
              {policy_rule_type.compliance_policy_references.length > 0 && (
                <div className={classes.complianceStandardReferences}>
                  <Typography gutterBottom variant="body2">
                    Compliance Standard References
                  </Typography>

                  <ComplianceTagStrip complianceReferences={policy_rule_type.compliance_policy_references} granular />
                </div>
              )}
            </Grid>

            {/* Policy rule type card relative components */}
            <PolicyRuleTypesCardRelativeComponents policy_rule_type_id={policy_rule_type.id} />

            {/* Error Message */}
            {error && <Message message={error.message} variant="error" />}
          </Grid>
        ) : (
          <div style={{ display: 'block', height: accordianDetailsHeight }} />
        )}
      </AccordionDetails>

      <AccordionActions>
        {isExpanded ? (
          <Grid
            container
            ref={ref => {
              if (!ref) return;

              setAccordianActionsHeight(ref.getBoundingClientRect().height);
            }}
          >
            <Grid container item justifyContent="flex-end" xs={12}>
              {/* Edit Configuration */}
              {/* Is this policy rule configurable? */}
              {POLICY_RULE_TYPE_EXTERNAL_CONFIGURATION_LOOKUP[policy_rule_type.id] && (
                <ExtLink target="_self" to={POLICY_RULE_TYPE_EXTERNAL_CONFIGURATION_LOOKUP[policy_rule_type.id]}>
                  <Button color="primary" size="small">
                    Edit Configuration
                  </Button>
                </ExtLink>
              )}

              {/* Activate | Deactivate */}
              <Button
                aria-label={
                  policy_rule_type.policy_rule
                    ? `Deactivate ${policy_rule_type.title}`
                    : `Activate ${policy_rule_type.title}`
                }
                color="primary"
                disabled={policyRulesCreateLoading || policyRulesRemoveLoading || policyRulesUpdateLoading}
                endIcon={
                  (policyRulesCreateLoading || policyRulesRemoveLoading) && (
                    <CircularProgress aria-label={`Activating ${policy_rule_type.title}`} size={15} />
                  )
                }
                onClick={handleTogglePolicyRuleButton}
                size="small"
                variant={policy_rule_type.policy_rule ? 'text' : 'contained'}
              >
                {policy_rule_type.policy_rule ? 'Deactivate Rule' : 'Activate rule'}
              </Button>

              {/* Edit Relevance/Severity */}
              {/* Is this policy rule relevance editable? */}
              {policy_rule_type.policy_rule && (
                <PolicyRuleTypesCardEditRelevanceButton
                  disabled={policyRulesRemoveLoading}
                  loading={policyRulesUpdateLoading}
                  onEditRelevanceClick={handleEditRelevanceItemClicked}
                  policy_rule_relevance={policy_rule_type.policy_rule.relevance}
                  policy_rule_type_default_relevance={policy_rule_type.default_relevance}
                  policy_rule_type_title={policy_rule_type.title}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div style={{ display: 'block', height: accordianActionsHeight }} />
        )}
      </AccordionActions>
    </Accordion>
  );
};
export const PolicyRuleTypesCardImplementation = PolicyRuleTypesCardImplementationComponent;
