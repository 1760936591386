import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { getBadgeImageFromBadgeType } from './util';

const SIZE = 48;

const useStyles = makeStyles({
  container: {
    filter: ({ enabled }) => 'drop-shadow( 0px 0px 2px #000 ) ' + (enabled ? '' : 'grayscale(1)'),
    height: '100%',
    opacity: ({ enabled }) => (enabled ? 1 : 0.7),
    width: '100%',
  },
});

/*
 * Badge icon representing difficult to achieve protection metrics.
 */
function ProtectionBadge(props) {
  const { type, enabled } = props;
  const classes = useStyles(props);

  const source = getBadgeImageFromBadgeType(type);

  return (
    <div className={classes.container}>
      <svg viewBox={`0 0 ${SIZE} ${SIZE}`}>
        {/* NOTE: SVG filter color matrix used to keep IE11 happy. */}
        <filter id="grayscale">
          <feColorMatrix
            type="matrix"
            values="0.3333 0.3333 0.3333 0   0
                      0.3333 0.3333 0.3333 0   0
                      0.3333 0.3333 0.3333 0   0
                      0      0      0      0.8   0"
          />
        </filter>
        <image
          alt={type}
          filter={!enabled ? 'url(#grayscale)' : null}
          height={SIZE}
          style={{ height: '100%', width: '100%' }}
          width={SIZE}
          xlinkHref={source}
        />
      </svg>
    </div>
  );
}

export default memo(ProtectionBadge);
