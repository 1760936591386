import { downloadFile } from '@utils/downloadFile';

export const recentlyDismissed = user => {
  const { renewal_reminder_last_dismissed } = user;
  if (!renewal_reminder_last_dismissed || !new Date(renewal_reminder_last_dismissed)) {
    return false;
  }
  const currentTimeStamp = new Date().getTime();
  const oneDayTimeStamp = 24 * 60 * 60 * 1000;
  const dismissedTimeStamp = new Date(renewal_reminder_last_dismissed).getTime();
  // return true if last dismissed is over expected days
  return currentTimeStamp - 7 * oneDayTimeStamp < dismissedTimeStamp;
};

export const getSubscriptionEndDateInDays = subscription_end_date => {
  const subscriptionEndTime = new Date(subscription_end_date).getTime();
  const differenceInTime = subscriptionEndTime - new Date().getTime();
  // To calculate the number of days left
  return Math.trunc(differenceInTime / (1000 * 3600 * 24));
};

export const isBillingAdminUser = (login_email, billing_contacts) => {
  return login_email && billing_contacts && billing_contacts.includes(login_email) ? true : false;
};

export const formatMoney = amountArg => {
  let amount = amountArg;
  let decimalCount = 2;
  const decimal = '.';
  const thousands = ',';
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-' : '';

  const i = parseInt((amount = Math.abs(amount || 0).toFixed(decimalCount))).toString();
  const j = i.length > 3 ? i.length % 3 : 0;

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(parseInt(amount) - parseInt(i))
          .toFixed(decimalCount)
          .slice(2)
      : '')
  );
};

export const downloadPdf = (blob, fileName) => {
  const url = URL.createObjectURL(blob);
  downloadFile({
    fileName,
    url,
  });
  URL.revokeObjectURL(url);
};
