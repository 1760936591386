import React, { memo } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import Label from './Label';

const useStyles = makeStyles(() => ({
  container: ({ small, rounded, backgroundColor, color, maxWidth, verticalAdjustment, width }) => ({
    '&&': {
      alignItems: 'center',
      backgroundColor: typeof backgroundColor === 'string' ? backgroundColor : palette.gray45,
      borderRadius: rounded ? 16 : 4,
      color: typeof color === 'string' ? color : palette.gray20,
      display: 'inline-flex',
      fontSize: small ? 12 : 14,
      height: '1.8em',
      marginTop: verticalAdjustment || 'initial',
      maxWidth: maxWidth,
      padding: small ? '2px 4px' : '2px 8px',
      width: width || 'fit-content',
    },
  }),
  icon: {
    '&&': {
      '& img, & svg': {
        maxHeight: '100%',
      },
      alignItems: 'center',
      display: 'flex',

      height: '100%',
    },
  },
  label: ({ icon }) => ({
    '&&': {
      marginLeft: icon ? 4 : 0,
      maxWidth: 250,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
}));

function Tag({
  small,
  icon,
  label,
  backgroundColor,
  color,
  tooltipText,
  rounded = false,
  maxWidth,
  verticalAdjustment,
  noPadding,
  width,
}) {
  const classes = useStyles({
    backgroundColor,
    color,
    icon,
    maxWidth,
    noPadding,
    rounded,
    small,
    verticalAdjustment,
    width,
  });

  const tooltipTitle = typeof tooltipText === 'string' ? tooltipText : label;

  return (
    <Tooltip aria-label={tooltipTitle} disableInteractive title={tooltipTitle}>
      {/* Span is used for Tooltip to register content area. */}
      <span style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}>
        <Label noPadding={noPadding} rounded={rounded} variant="transparent">
          <div className={classes.container}>
            <div className={classes.icon}>{icon ? icon : <span style={{ height: 14 }} />}</div>
            <span className={classes.label}>{label}</span>
          </div>
        </Label>
      </span>
    </Tooltip>
  );
}

export default memo(Tag);
