import React, { memo } from 'react';
import { Grid, Tab as MuiTab, Tabs as MuiTabs, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { fontFamilies, palette } from '@dt/theme';
import { Link } from '@reach/router';

/*
 * Calculate the width of text.
 */
const measureTextWidth = text => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `16px ${fontFamilies.primary}`;
  // Unable to calculate height here.
  // See https://stackoverflow.com/a/45789011/422312
  const size = context.measureText(text);

  return size.width;
};

const useStyles = makeStyles({
  indicator: {
    backgroundColor: palette.brand30,
  },
  root: props =>
    props.hasBorder
      ? {
          borderBottom: '1px solid #9AA5B1',
          width: '100%',
        }
      : {},
  tab_root: {
    '&[data-badge="true"]': {
      '&:after': {
        background: palette.red20,
        borderRadius: '50%',
        content: '""',
        display: 'block',
        height: '10px',
        position: 'absolute',
        right: 0,
        top: '6px',
        width: '10px',
      },
    },
    color: palette.gray35,
    marginRight: 8,
    maxWidth: 'inherit',
    minWidth: 72,
  },
  tab_selected: { color: palette.brand30 },
});

const Tabs = ({ hasBorder, isLoading, currentTab, baseUrl, queryParams, tabs, onChange }) => {
  const classes = useStyles({ hasBorder });

  if (isLoading) {
    return (
      <Grid container>
        {tabs.map(({ value, label }) => {
          // Assumes that the provided text will be the same after loading.
          const textWidth = measureTextWidth(label);
          const xPadding = 24;
          const width = textWidth + xPadding;

          return (
            <Skeleton
              height={48}
              key={value}
              style={{ display: 'inline-block', marginRight: 8 }}
              variant="rectangular"
              width={width}
            />
          );
        })}
      </Grid>
    );
  }

  const tabsChildren = tabs.map(({ value, badge, label, disabled, id, tooltip }, i) => {
    // prettier-ignore
    const formattedValue = value.replace(' ', '-').toLocaleLowerCase();
    const to = `${currentTab ? baseUrl || '../' : ''}${formattedValue}${queryParams ? '?' + queryParams : ''}`;

    return !onChange ? (
      <MuiTab
        aria-label={label}
        classes={{
          root: classes.tab_root,
          selected: classes.tab_selected,
        }}
        component={Link}
        data-badge={badge || false}
        data-testid={id ? id : label}
        disabled={disabled || false}
        key={i}
        label={
          <Tooltip disableInteractive title={tooltip || ''}>
            <span>{label}</span>
          </Tooltip>
        }
        to={to}
        value={value}
      />
    ) : (
      <MuiTab
        aria-label={label}
        classes={{
          root: classes.tab_root,
          selected: classes.tab_selected,
        }}
        data-testid={id ? id : label}
        disabled={disabled || false}
        key={i}
        label={label}
        value={value}
      />
    );
  });

  return (
    <Grid container>
      {!onChange ? (
        <MuiTabs classes={{ indicator: classes.indicator, root: classes.root }} value={currentTab || tabs[0].value}>
          {tabsChildren}
        </MuiTabs>
      ) : (
        <MuiTabs
          classes={{ indicator: classes.indicator, root: classes.root }}
          onChange={onChange}
          value={currentTab || tabs[0].value}
        >
          {tabsChildren}
        </MuiTabs>
      )}
    </Grid>
  );
};

export default memo(Tabs);
