export const palette = {
  accent: '#f2f2f2',

  // platform specific 'official' colors
  androidGreen: '#A4C639',

  appleSkyBlue: '#78C5EF',

  attention: '#FF2D55',

  bad: '#E57373',

  bgColor: '#fff',

  black: '#000',

  // Symlink of blue30
  blue: '#4099FF',

  blue10: '#003B80',

  blue20: '#0053B3',

  blue30: '#4099FF',

  blue40: '#A2CDFF',

  blue50: '#C5DFFD',

  // Symlink of brand30
  brand: '#123770',

  brand10: '#0C0F4C',

  brand20: '#0A215C',

  brand30: '#123770',

  brand35: '#356DAF',

  brand40: '#5A96CD',

  brand45: '#94BFDF',

  brand50: '#CCE3F5',

  brightBg: '#fff',

  brown: '#8D6E63',

  corrected: '#16ac8b',

  darkBg: '#1C1429',

  darkestBg: '#000',

  error: '#FF4867',

  faded: '#777',

  good: '#4CAF50',

  // Symlink of gray30
  gray: '#616E7C',

  gray10: '#1F2933',

  gray20: '#3E4C59',

  gray30: '#616E7C',

  gray35: '#9AA5B1',

  gray40: '#CBD2D9',

  gray45: '#E4E7EB',

  gray50: '#F5F7FA',

  // Symlink of green30
  green: '#4aa564',

  green10: '#215f2e',

  green20: '#2e8540',

  green30: '#4aa564',

  green40: '#94bfa2',

  green50: '#e7f4e4',

  inacitveColor: '#BBC0D4',

  info: '#29B6F6',

  navColor: '#878DA3',

  // Symlink of orange30
  orange: '#FF7043',

  orange10: '#C95C08',

  orange20: '#DC6832',

  orange30: '#F87530',

  orange40: '#FAAA7F',

  orange50: '#FFD2B8',

  // Symlink of red30
  red: '#e31c3d',

  red10: '#981b1e',

  red20: '#cd2026',

  red30: '#e31c3d',

  red40: '#e59393',

  red50: '#f9dede',

  // @Deprecated
  // Please do not use any of the colors below after January 2019 or you will be fired!
  // primary: '#123770',
  // warning: '#f9c642',
  secondary: '#4099FF',

  tableDark: '#f9f9fa',

  tableLight: '#fdfdfd',

  // @lab colors
  // These colors are experimental and can be changed/deprecated anytime!
  teal: '#00B8D9',

  white: '#fff',

  // Symlink of yellow30
  yellow: '#f9c642',

  yellow10: '#e39e02',

  yellow20: '#fdb81e',

  yellow30: '#f9c642',

  yellow40: '#fad980',

  yellow50: '#fff1d2',
  yellowHighlight: '#FAFA33',
};

export const fontFamilies = {
  mono: `'Fira Mono', Menlo, monospace`,
  primary: `'Rubik', 'Roboto', -apple-system, BlinkMacSystemFont, 'sans-serif'`,
};

export const fontSizes = {
  huge: 32,
  large: 16,
  medium: 13,
  small: 12,
  tiny: 9,
};

export const spacing = {
  headerHeight: 50,
  menuWidth: 231,
};

export const dateFormats = {
  date: 'MM/dd/yyyy',
  date12h: 'MM/dd/yyyy, h:mm aa',
  date24h: 'MM/dd/yyyy, HH:mm',
  yearFirstDate: 'MM/dd/yyyy',
};

// @SJ TODO: This mapping is an artifact of the "type" prop chain that was used for SecurityTaskIndicator, SecurityTaskIcon, IconCountIndicator (and a myriad other related components) throughout Apollo. Patching for now, but in the future need to refactor these components to not use this convention, in favor of the below mappings which are more specific.
export const oldPaletteToNewPaletteMap = {
  bad: palette.red,
  good: palette.green,
  info: palette.blue,
  neutral: palette.gray,
  warning: palette.yellow,
};

export const materialTheme = opts => ({
  components: {
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        variant: 'inherit',
      },
      styleOverrides: {
        // variant = contained & color = Secondary
        containedInherit: {
          '&:hover': {
            backgroundColor: palette.gray40,
          },
          backgroundColor: 'none',
          color: palette.gray20,
        },

        // variant = contained & color = default
        containedPrimary: {
          '&:hover': {
            backgroundColor: palette.brand10,
          },
          backgroundColor: palette.brand30,
          color: palette.white,
        },

        containedSecondary: {
          '&:hover': {
            backgroundColor: palette.gray40,
          },
          backgroundColor: 'none',
        },
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          backgroundColor: 'none',
          boxShadow: 'none',
        },

        // variant = text & color = Primary
        textPrimary: {
          '&:hover': {
            backgroundColor: palette.gray45,
          },
          backgroundColor: 'none',
          color: palette.brand30,
        },
        // variant = text & color = Secondary
        textSecondary: {
          '&:hover': {
            backgroundColor: palette.gray45,
          },
          backgroundColor: 'none',
          color: palette.brand30,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            '& label': {
              background: 'none',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            '& label': {
              background: 'white',
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          padding: '0 4px',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  palette: {
    default: {
      dark: palette.gray40,
      main: palette.gray30,
    },
    error: {
      dark: palette.red20,
      light: palette.red40,
      main: palette.red,
    },
    grey: {
      100: palette.gray50,
      200: palette.gray45,

      300: palette.gray45,
      400: palette.gray40,

      50: palette.gray50,
      500: palette.gray40,

      600: palette.gray35,
      700: palette.gray30,
      800: palette.gray20,
      900: palette.gray10,
    },
    primary: {
      dark: palette.brand20,
      light: palette.brand40,
      main: palette.brand,
    },

    secondary: {
      dark: 'rgb(245, 245, 245)',
      light: palette.gray40,
      main: 'rgb(228, 231, 235)',
    },
    text: {
      disabled: palette.gray35,
      hint: palette.gray40,
      primary: palette.gray20,
      secondary: palette.gray30,
    },
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiLink: {
      component: (opts && opts.linkComponent) || null,
    },
  },
  typography: {
    body1: {
      color: palette.gray20,
      fontSize: '16px',
    },

    // body2
    body2: {
      color: palette.gray30,
      fontSize: '14px',
    },

    // caption
    caption: {
      color: palette.gray30,
    },

    fontFamily: fontFamilies.primary,

    fontSize: 14,

    // Header5
    h5: {
      color: palette.gray20,
    },

    // Subheader
    h6: {
      color: palette.gray20,
      fontSize: '0.8rem',
      fontWeight: 500,
      letterSpacing: '0.0075em',
      lineHeight: 1.6,
      textTransform: 'uppercase',
    },

    useNextVariants: true,
  },
});

export default () => ({
  dataTheorem: {
    fontFamily: fontFamilies.primary,
    fontFamilyAlt: fontFamilies.primary,
    fontFamilyBold: fontFamilies.primary,
    fontSize: fontSizes,
    palette: palette,
    spacing: spacing,
  },
  dateFormats,
  fontFamily: fontFamilies.primary,
  palette: {
    accent1Color: '#4099FF',

    // Override Material-ui primary color to our brand primary color
    // more options to replace: https://github.com/mui-org/material-ui/blob/v0.x/src/styles/getMuiTheme.js#L21
    primary1Color: palette.brand,
  },
});
