import React from 'react';
import UnknownIcon from '@mui/icons-material/HelpOutline';
import { palette } from '@dt/theme';
import Tag from './Tag';
import AwsLogo from './svg/aws_logo.svg';
import AxwayLogo from './svg/axway_logo.svg';
import AzureLogo from './svg/azure_logo.svg';
import GcpLogo from './svg/gcp_logo.svg';
import KongLogo from './svg/kong_logo.svg';
import MulesoftLogo from './svg/mulesoft_logo.svg';

const ICON_SIZE = 14;

function TagCloudResourceId({
  cloud_resource_decorated: {
    aws_arn,
    gcp_project_id,
    azure_tenant_id,
    axway_org_id,
    mulesoft_domain,
    kong_workspace_name,
  },
}) {
  /*
   * NOTE: Not every cloud resource has a corresponding resource id at this time.
   *       These are the ones that are currently not supported.
   *
   *       - APIGEE
   *
   * The unknown cases should never happen, if it does the cloud resource needs to be inspected on the backend for bugs.
   */
  const { label, icon } = (function () {
    if (aws_arn) {
      return {
        // NOTE: ARN is included in aws_arn.
icon: <img alt="AWS icon" height={ICON_SIZE} src={AwsLogo} width={ICON_SIZE} />, 
        label: `${aws_arn}`,
      };
    } else if (gcp_project_id) {
      return {
        icon: <img alt="GCP icon" height={ICON_SIZE} src={GcpLogo} width={ICON_SIZE} />,
        label: `PROJECT: ${gcp_project_id}`,
      };
    } else if (azure_tenant_id) {
      return {
        icon: <img alt="Azure icon" height={ICON_SIZE} src={AzureLogo} width={ICON_SIZE} />,
        label: `TENANT ID: ${azure_tenant_id}`,
      };
    } else if (axway_org_id) {
      return {
        icon: <img alt="Axway icon" height={ICON_SIZE} src={AxwayLogo} width={ICON_SIZE} />,
        label: `ORG ID:${axway_org_id}`,
      };
    } else if (mulesoft_domain) {
      return {
        icon: <img alt="Mulesoft icon" height={ICON_SIZE} src={MulesoftLogo} width={ICON_SIZE} />,
        label: `DOMAIN: ${mulesoft_domain}`,
      };
    } else if (kong_workspace_name) {
      return {
        icon: <img alt="Kong icon" height={ICON_SIZE} src={KongLogo} width={ICON_SIZE} />,
        label: `WORKSPACE: ${kong_workspace_name}`,
      };
    }
    return {
      icon: <UnknownIcon style={{ color: palette.gray, height: ICON_SIZE, width: ICON_SIZE }} />,
      label: 'RESOURCE ID: unknown',
    };
  })();

  return <Tag icon={icon} label={label} />;
}

export default React.memo(TagCloudResourceId);
