import React, { memo } from 'react';
import { TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Tag } from '@dt/material-components';

const InventoryTableCellDiscoveredVia = props => {
  if (props.loading) {
    return (
      <TableCell>
        <Box alignItems={'center'} display={'flex'}>
          <Skeleton animation="wave" height={40} variant="text" width={100} />
        </Box>
      </TableCell>
    );
  }

  const { iconUrl, name, maxWidth } = props;

  return (
    <TableCell>
      <Tag
        icon={<img alt={name} src={iconUrl} style={{ height: 14, width: 14 }} />}
        label={name}
        maxWidth={maxWidth || '100%'}
      />
    </TableCell>
  );
};

export default memo(InventoryTableCellDiscoveredVia);
