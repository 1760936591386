import React, { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { Drawer, Grid } from '@mui/material';
// @ts-ignore
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore TODO-IMPLEMENT-APOLLO-LINK-SCHEMA-REST
import { useQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore TODO-IMPLEMENT-TYPES
import horizon_notifications from '@dt/graphql-support/horizon/notification_events';
// @ts-ignore TODO-IMPLEMENT-TYPES
import sevenhell_notifications from '@dt/graphql-support/sevenhell/portal_notifications';
import { NotificationItem, NotificationItemError, NotificationItemLoadingSkeleton, NotificationItemZeroState, // @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
 } from '@dt/material-components/notifications';
import SlackLogo from '@dt/material-components/svg/slack_logo.svg';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import { differenceInDays } from 'date-fns';
import { dateFormats } from '../util/dateFormats';
import { formatDateDefault } from '../util/formatDateDefault';
const NotificationsDrawer = ({ isOpen, toggle }) => {
    const { data: horizonNotificationsData, loading: horizonNotificationsLoading, error: horizonNotificationsError, fetchMore: horizonNotificationsFetchMore, } = useQuery(horizon_notifications.list);
    const { data: sevenhellNotificationsData, loading: sevenhellNotificationsLoading, error: sevenhellNotificationsError, fetchMore: sevenhellNotificationsFetchMore, } = useQuery(sevenhell_notifications.list);
    const horizonNotifications = horizonNotificationsData?.notification_events_list?.events
        ? horizonNotificationsData.notification_events_list.events
        : [];
    const sevenhellNotifications = sevenhellNotificationsData?.portal_notifications_list?.portal_notifications
        ? sevenhellNotificationsData.portal_notifications_list.portal_notifications
        : [];
    const notificationsLoading = sevenhellNotificationsLoading || horizonNotificationsLoading;
    const notificationsError = sevenhellNotificationsError || horizonNotificationsError;
    let currentDate = new Date(Date.now());
    let hasTodayBeenChecked = false;
    if (horizonNotificationsError) {
        console.error('horizonNotificationsError:', horizonNotificationsError);
    }
    if (sevenhellNotificationsError) {
        console.error('sevenhellNotificationsError:', sevenhellNotificationsError);
    }
    const notifications = useMemo(() => [...horizonNotifications, ...sevenhellNotifications].sort((a, b) => {
        if (a.date_created && b.date_created) {
            if (a.date_created > b.date_created) {
                return -1;
            }
            return 1;
        }
        return 0;
    }), [horizonNotifications, sevenhellNotifications]);
    return (React.createElement(Drawer, { PaperProps: {
            style: {
                overflowX: 'hidden',
                width: 488 + 72,
            },
        }, anchor: "right", "aria-labelledby": 'Notifications Drawer', onClose: toggle, open: isOpen, style: { zIndex: 1100 } },
        React.createElement(Grid, { alignItems: "flex-start", container: true, direction: "column", spacing: 2, style: { padding: 16 } },
            React.createElement(Grid, { item: true },
                React.createElement("div", { style: { alignItems: 'center', display: 'flex', margin: '8px 0' } },
                    React.createElement(Text, { component: "span", style: { marginRight: 4 }, variant: "titleS" }, "Alerts"),
                    React.createElement(Text, { component: "span", variant: "body" },
                        "(contact ",
                        React.createElement("a", { href: "mailto:support@datatheorem.com" }, "support@datatheorem.com"),
                        " to join the Slack Channel"),
                    React.createElement("img", { alt: `Slack Icon`, height: 18, src: SlackLogo, style: { marginLeft: 4, marginRight: 4 }, width: 18 }),
                    React.createElement(Text, { component: "span", variant: "body" }, ")"))),
            !notifications && !notificationsLoading && !notificationsError && React.createElement(NotificationItemZeroState, null),
            notifications
                ? notifications.map(notification => {
                    let dateHeader = null;
                    if (differenceInDays(Date.now(), notification.date_created) === 0 && !hasTodayBeenChecked) {
                        dateHeader = 'Today';
                        hasTodayBeenChecked = true;
                    }
                    if (differenceInDays(currentDate, notification.date_created) > 0) {
                        dateHeader = formatDateDefault({ date: notification.date_created, formatStr: dateFormats.MMMddyyyy });
                        currentDate = notification.date_created;
                    }
                    return (React.createElement(Grid, { item: true, key: notification.id },
                        Boolean(dateHeader) && (React.createElement(Text, { style: { color: palette.gray35, margin: 0 }, variant: "titleXS" }, dateHeader)),
                        React.createElement(NotificationItem, { notification: notification })));
                })
                : null,
            notificationsLoading &&
                [0, 1, 2, 3, 4, 5, 6].map(i => {
                    return (React.createElement(Grid, { item: true, key: i },
                        React.createElement(NotificationItemLoadingSkeleton, null)));
                }),
            !sevenhellNotificationsLoading &&
                sevenhellNotificationsData?.portal_notifications_list?.pagination_information?.next_cursor && (React.createElement(Grid, { item: true },
                React.createElement(Waypoint, { onEnter: () => {
                        sevenhellNotificationsFetchMore && sevenhellNotificationsFetchMore();
                    } }))),
            !horizonNotificationsLoading &&
                horizonNotificationsData?.notification_events_list?.pagination_information?.next_cursor && (React.createElement(Grid, { item: true },
                React.createElement(Waypoint, { onEnter: () => {
                        horizonNotificationsFetchMore && horizonNotificationsFetchMore();
                    } }))),
            (horizonNotificationsError || sevenhellNotificationsError) && React.createElement(NotificationItemError, null))));
};
export default NotificationsDrawer;
