import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyle = makeStyles({
  drawer: {
    
    
    
    '&.open': {
      minWidth: '256px',
      width: '256px',
    },
    


backgroundColor: palette.brand30,
    


height: '100%',
    


minWidth: '72px',
    


overflowX: 'hidden',
    


transition: 'width 160ms cubic-bezier(1, 0, 0, 1)',
    


width: '72px',
    


willChange: 'width',

    // Just enough to go above the `StickyTable` but still below MUI `Tooltip` (͠≖ ͜ʖ͠≖)
// Refer to https://material-ui.com/customization/z-index for list of z-indexes
// used by Material UI components
zIndex: 1201,
  },
});

function Drawer({ open, children }) {
  const css = useStyle();
  return <div className={`${css.drawer} ${open ? 'open' : ''}`}>{children}</div>;
}

export default memo(Drawer);
