import React, { memo } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Checkbox, List, ListItem, ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
// @ts-ignore TODO-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore TODO-THEME
import { palette } from '@dt/theme';
const useStyles = makeStyles({
    itemLogo: {
        justifyContent: 'center',
    },
    list: {
        padding: 0,
        paddingLeft: '16px',
    },
    mainList: {
        maxHeight: 350,
        overflow: 'auto',
        overflowX: 'auto',
    },
});
const TableFiltersSeverity = ({ selectedList, onChange }) => {
    const classes = useStyles();
    const handleChange = (id) => {
        if (selectedList.includes(id)) {
            onChange(selectedList.filter(item => item !== id));
        }
        else {
            onChange([id]);
        }
    };
    return (React.createElement(List, { className: classes.mainList, component: "nav" },
        React.createElement(ListItem, { button: true, dense: true, onClick: () => handleChange('FAILED'), selected: selectedList.includes('FAILED') },
            React.createElement(Checkbox, { checked: Boolean(selectedList.includes('FAILED')) }),
            React.createElement(ListItemIcon, { className: classes.itemLogo },
                React.createElement(Avatar, { style: { backgroundColor: palette.red50, height: 24, width: 24 } },
                    React.createElement(WarningIcon, { style: { fill: palette.red10, fontSize: 16 } }))),
            React.createElement(Text, { component: 'div', noWrap: true, variant: 'body' }, "FAIL")),
        React.createElement(ListItem, { button: true, dense: true, onClick: () => handleChange('PASSED'), selected: selectedList.includes('PASSED') },
            React.createElement(Checkbox, { checked: Boolean(selectedList.includes('PASSED')) }),
            React.createElement(ListItemIcon, { className: classes.itemLogo },
                React.createElement(Avatar, { style: { backgroundColor: palette.green50, height: 24, width: 24 } },
                    React.createElement(ThumbUpIcon, { style: { fill: palette.green10, fontSize: 16 } }))),
            React.createElement(Text, { component: 'div', noWrap: true, variant: 'body' }, "PASS"))));
};
export default memo(TableFiltersSeverity);
