import React, { memo, useEffect, useState } from 'react';
import Block from '@mui/icons-material/Block';
import { Tooltip } from '@mui/material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import tracking, { dataCreators } from '@dt/analytics';
import policy_violations from '@dt/graphql-support/horizon/policy_violations';
import { palette } from '@dt/theme';
import Text from './Text';

/*
 * Allows the user to close a policy violation with provided defaults or with a custom reason.
 *
 * @param policy_violation_id - Used with the graphql patch when updating the policy violation
 *
 * @example
 *     <PolicyViolationWontFixButton policy_violation_id={policy_violation.id} />
 */
const PolicyViolationWontFixButtonComponent = function PolicyViolationWontFixButton({
  policy_violation_id,
  onUpdatePolicyViolation,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [givenReason, setGivenReason] = useState('');

  const [patchPolicyViolation, { loading: patchPolicyViolationLoading, error: patchPolicyViolationError }] =
    useMutation(policy_violations.patch, {
      update: (cache, result) => {
        if (result?.errors) {
          return null;
        }
        onUpdatePolicyViolation?.(cache, result);
      },
    });
  const { data: policyViolationsGetData, refetch: policyViolationsGetRefetch } = useQuery(policy_violations.v2_get, {
    variables: {
      id: policy_violation_id,
    },
  });

  // When an error occurs, even if we didn't open the dialog, open the dialog to show the error.
  useEffect(() => {
    if (!patchPolicyViolationError) {
      return;
    }
    setIsDialogOpen(true);
  }, [patchPolicyViolationError]);

  // Event Handlers.
  const handleMenuOnClose = () => {
    // Close menu.
    setIsMenuOpen(false);
    setAnchorEl(null);
  };
  const handleButtonClick = e => {
    setIsMenuOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const handleOnSubmit = async reason => {
    // Set given reason, could be a predefined reason.
    setGivenReason(reason);

    // Close policy violation.
    const { errors } = await patchPolicyViolation({
      variables: {
        body: {
          exception_explanation: reason,
          exception_type: 'WONT_FIX',
        },
        id: policy_violation_id,
      },
    });

    if (!errors) {
      // Success.
      // Track when policy violations are closed.
      tracking(dataCreators.policyViolationExceptionChange(reason));

      // Invalidate policy violation cache.
      policyViolationsGetRefetch();

      return true;
    } else {
      // Failure
      return false;
    }
  };

  const isPolicyViolationOpen = policyViolationsGetData?.policy_violation_details_v2.status === 'OPEN';

  // Occurs when the user closes a policy violation with a predefined response through the menu.
  const isMenuButtonLoading = !isDialogOpen && patchPolicyViolationLoading;

  return (
    <>
      <Tooltip title={!isPolicyViolationOpen ? 'Policy violation already closed' : ''}>
        {/* Span is used for Tooltip to register content area. */}
        <span>
          <Button
            disabled={!isPolicyViolationOpen || isMenuButtonLoading}
            onClick={handleButtonClick}
            size="small"
            variant="contained"
          >
            <Block style={{ fontSize: 14, marginRight: 4 }} />
            Close Violation {isMenuButtonLoading && <CircularProgress size={14} style={{ marginLeft: 8 }} />}
          </Button>
        </span>
      </Tooltip>
      <Menu anchorEl={anchorEl} disableScrollLock onClose={handleMenuOnClose} open={isMenuOpen}>
        {["Won't fix", 'Asset is unauthenticated by design', 'Compensating control in place', 'Risk is accepted'].map(
          reason => (
            <MenuItem
              key={reason}
              onClick={() => {
                handleOnSubmit(reason + '.');
                handleMenuOnClose();
              }}
            >
              {reason}
            </MenuItem>
          ),
        )}
        <MenuItem
          onClick={() => {
            setIsDialogOpen(true);
            handleMenuOnClose();
          }}
        >
          Other
        </MenuItem>
      </Menu>

      <Dialog
        PaperProps={{ style: { width: '100%' } }}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        open={isDialogOpen}
      >
        <DialogTitle>Close Violation</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Please provide an explanation"
            margin="dense"
            minRows={3}
            multiline={true}
            onChange={e => setGivenReason(e.target.value)}
            placeholder="Please provide an explanation"
            required
            value={givenReason}
          />
          {patchPolicyViolationError && (
            <div>
              <Text color={palette.red30} variant="body">
                An error occurred while updating the policy violation.
              </Text>
              <Text color={palette.red30} variant="body">
                {patchPolicyViolationError.message}
              </Text>
              <Text color={palette.red30} variant="body">
                Please try again later or contact us at support@datatheorem.com.
              </Text>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button key={1} onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={patchPolicyViolationLoading || givenReason.length <= 0}
            key={2}
            onClick={() => {
              (async () => {
                const success = await handleOnSubmit(givenReason);
                setIsDialogOpen(!success);
              })();
            }}
          >
            Confirm {patchPolicyViolationLoading && <CircularProgress size={15} style={{ marginLeft: 8 }} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const PolicyViolationWontFixButton = memo(PolicyViolationWontFixButtonComponent);
